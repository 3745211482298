<!--

      This view displays the list of recent orders. It is reachable from within
      the profile tab.

 -->

<template>
  <Page title='Produkte suchen' :scrollevents="true" :shoppingList="true">
    <ProductSearch />      
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import ProductSearch from '@/components/ProductSearch.vue'
import ProductCard from '@/components/cards/ProductCard'
import NewCard from '@/components/cards/NewCard.vue'

import {
  IonSearchbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSkeletonText
} from '@ionic/vue'

export default {
  name: 'productsearch',
  components: {
    Page,
    ProductSearch,
    ProductCard,
    NewCard,
    IonSearchbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSkeletonText
  },
  data () {
    return {
      lastResultsObj: null,
      visibleData: [],
      searchTerm: '',
    }
  },
  computed: {
    showSkeletonCards () {
      return !this.visibleData
    },
    showSorryNoResults () {
      return this.lastResultsObj && this.visibleData && this.visibleData.length === 0
    }
  },
  methods: {
    productSearch (event) {
      this.resetProductSearch()
      if (event && event.target && event.target.value && event.target.value != '') {
        this.visibleData = null
        this.searchInProgress = true
        this.productSearchTerm = event.target.value
        let searchUrl = this.$store.state.api.shop.productSearch + '?query=' + event.target.value.toLowerCase()

        const details = {
          url: searchUrl,
          method: 'GET'
        }
        this.$store.dispatch('apiSend', details ).then( results => {
          if (results && results.results) {
            if (results.count > results.results.length) {
              this.thereIsMoreToLoad = true
            } else {
              this.thereIsMoreToLoad = false
            }
            this.visibleData = results.results
            this.lastResultsObj = results
            this.searchInProgress = null
          }
          this.$store.dispatch('logAnalyticsEvent', { name: 'searched_for_product' })
        })
      }
      this.searchTerm = event.target.value
    },

    loadNextPage (event) {
      const details = {
        url: this.lastResultsObj.next
      }
      this.$store.dispatch('apiSend', details ).then( nextpage => {
        if (nextpage && nextpage.count) {
          this.visibleData = this.visibleData.concat(nextpage.results)
          this.lastResultsObj = nextpage
          event.target.complete()
          if (!nextpage.next) {
            event.target.disabled = true
          }
        }
      })
    },

    resetProductSearch () {
      this.searchTerm = ''
      this.visibleData = []
      this.lastResultsObj = null
    }
  },
  ionViewDidEnter () {
    this.$refs.searchbar.$el.setFocus()
  }
}
</script>

<style lang="scss">
@import './../../theme/main.scss';

.tag-bar {
  text-align: left;
  margin: 0 20px;
}

.products {
  margin: 20px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
</style>
